
import React from 'react';
import logo from './logo.svg';
import {Button} from 'antd';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
    <Button type="primary">Button</Button>
  </div>
  );
}

export default App;
